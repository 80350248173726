import { useRef } from 'react'
// import anime from 'animejs'
import { VStack } from '@chakra-ui/react'

import { css } from '@emotion/react'

interface Props {
  code?: number
}

function ErrorIcon({ code }: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   if (containerRef.current) {
  //     const animInstances: anime.AnimeInstance[] = []
  //     animInstances.push(
  //       anime({
  //         translateX: ['-4%', '4%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_1l`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['-3%', '3%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_2l`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['-2%', '2%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_3l`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['-1%', '1%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_4l`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['4%', '-4%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_1r`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['3%', '-3%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_2r`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['2%', '-2%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_3r`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     animInstances.push(
  //       anime({
  //         translateX: ['1%', '-1%'],
  //         targets: `.${containerRef.current.className
  //           .split(' ')
  //           .join('.')} #animate_layer_4r`,
  //         direction: 'alternate',
  //         easing: 'easeInOutSine',
  //         loop: true,
  //         duration: 1000,
  //       }),
  //     )

  //     return () =>
  //       animInstances.forEach((instance: anime.AnimeInstance) =>
  //         instance.pause(),
  //       )
  //   }

  //   // eslint-disable-next-line @typescript-eslint/no-empty-function
  //   return () => {}
  // }, [containerRef.current])

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      spacing="0px"
      ref={containerRef}
      layerStyle="errorIcon"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 2084 2084"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        css={css`
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linejoin: round;
          stroke-miterlimit: 2;
          width: 100%;
          height: 100%;
        `}
      >
        <g id="Layer_3">
          <ellipse
            cx="1041.67"
            cy="1617.5"
            rx="903.333"
            ry="61.667"
            css={css`
              fill: #184c8c;
            `}
          />
        </g>
        <g id="Layer_4">
          <g>
            <g>
              <path
                d="M1742.08,703.333l0,784.167c0,19.167 -12.083,35.417 -29.166,41.25c-4.584,1.667 -9.584,2.5 -15,2.5l-1278.33,0c-5.833,0 -11.666,-1.25 -17.083,-3.333c-15.833,-6.667 -27.083,-22.5 -27.083,-40.417l-0,-784.167c-0,-5.833 1.25,-11.666 3.333,-16.666c4.583,-10.834 12.917,-19.584 23.75,-23.75c5.417,-2.084 10.833,-3.334 17.083,-3.334l1278.75,0c12.084,0 23.334,5 31.25,12.917c7.5,7.5 12.5,18.75 12.5,30.833Z"
                css={css`
                  fill: #cecece;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M402.5,662.5l0,865.417c-15.833,-6.667 -27.083,-22.5 -27.083,-40.417l-0,-784.167c-0,-5.833 1.25,-11.666 3.333,-16.666c4.583,-10.834 13.333,-19.584 23.75,-24.167Z"
                css={css`
                  fill: #d8dbdd;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M1742.08,703.333l0,784.167c0,19.167 -12.083,35.417 -29.166,41.25l-0,-823.75c-0,-5.417 -2.5,-10.417 -6.25,-13.75c-3.334,-2.917 -7.5,-4.583 -12.5,-4.583l-1315.42,-0c6.667,-16.25 22.5,-27.5 40.833,-27.5l1278.75,-0c12.084,-0 23.334,5 31.25,12.916c7.5,7.917 12.5,19.167 12.5,31.25Z"
                css={css`
                  fill: #b0b4b6;
                  fill-rule: nonzero;
                `}
              />
              <rect
                x="429.167"
                y="709.167"
                width="1259.58"
                height="772.917"
                css={css`
                  fill: #fff;
                `}
              />
              <rect
                x="429.167"
                y="709.167"
                width="1259.58"
                height="115.417"
                css={css`
                  fill: #306ead;
                `}
              />
              <path
                d="M1576.67,797.083l-269.584,0c-16.666,0 -30.416,-13.75 -30.416,-30.416c-0,-10 5,-19.167 12.5,-24.584c5,-3.75 10.833,-5.833 17.5,-5.833l269.583,0c16.667,0 30.417,13.75 30.417,30.417c-0,9.166 -3.75,17.083 -10,22.5c-5,5 -12.084,7.916 -20,7.916Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
              <g>
                <g>
                  <path
                    d="M1307.08,782.083c-4.166,0 -8.333,-1.666 -11.666,-5c-6.25,-6.25 -6.25,-17.083 -0,-23.333c6.25,-6.25 17.083,-6.25 23.333,0c6.25,6.25 6.25,17.083 -0,23.333c-3.333,3.334 -7.5,5 -11.667,5Zm0,-26.666c-2.5,-0 -5,0.833 -7.083,2.916c-3.75,3.75 -3.75,10.417 -0,14.167c3.75,3.75 10.417,3.75 14.167,0c3.75,-3.75 3.75,-10.417 -0,-14.167c-1.667,-1.666 -4.584,-2.916 -7.084,-2.916Z"
                    css={css`
                      fill: #306ead;
                      fill-rule: nonzero;
                    `}
                  />
                </g>
                <g>
                  <path
                    d="M1329.17,791.25c-0.834,0 -1.667,-0.417 -2.084,-0.833l-12.5,-12.5c-1.25,-1.25 -1.25,-3.334 0,-4.584c1.25,-1.25 3.334,-1.25 4.584,0l12.5,12.5c1.25,1.25 1.25,3.334 -0,4.584c-0.834,0.416 -1.667,0.833 -2.5,0.833Z"
                    css={css`
                      fill: #306ead;
                      fill-rule: nonzero;
                    `}
                  />
                </g>
              </g>
              <path
                d="M481.811,749.761c9.359,-13.937 28.272,-17.653 42.209,-8.294c13.937,9.359 17.654,28.272 8.295,42.209c-9.359,13.937 -28.273,17.653 -42.21,8.295c-13.936,-9.359 -17.653,-28.273 -8.294,-42.21Z"
                css={css`
                  fill: #fff;
                `}
              />
              <path
                d="M598.228,738.541c15.495,-6.458 33.318,0.879 39.776,16.374c6.458,15.494 -0.878,33.317 -16.373,39.775c-15.495,6.459 -33.318,-0.878 -39.776,-16.373c-6.458,-15.495 0.879,-33.318 16.373,-39.776Z"
                css={css`
                  fill: #fff;
                `}
              />
              <path
                d="M742.917,766.667c-0,16.666 -13.75,30.416 -30.417,30.416c-16.667,0 -30.417,-13.75 -30.417,-30.416c0,-16.667 13.75,-30.417 30.417,-30.417c17.083,0 30.417,13.75 30.417,30.417Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
            </g>
            <g>
              <path
                d="M1927.92,757.5l-952.917,0c-24.167,0 -44.167,19.583 -44.167,44.167l0,72.916l996.667,0l0,-117.083l0.417,0Z"
                css={css`
                  fill: #cecece;
                  fill-rule: nonzero;
                `}
              />
              <rect
                x="984.583"
                y="807.083"
                width="943.333"
                height="67.083"
                css={css`
                  fill: #fff;
                `}
              />
              <rect
                x="984.583"
                y="807.083"
                width="943.333"
                height="67.083"
                css={css`
                  fill: #306ead;
                `}
              />
              <path
                d="M1928.33,834.583l-65.833,0c-6.667,0 -12.5,2.084 -17.5,5.834c-7.5,5.416 -12.5,14.583 -12.5,24.583c0,3.333 0.417,6.25 1.667,9.167l94.166,-0l0,-39.584Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
              <g>
                <g>
                  <path
                    d="M1875.42,874.167c5.416,-6.667 5,-15.834 -1.25,-22.084c-6.25,-6.25 -17.084,-6.25 -23.334,0c-5.833,5.834 -6.25,15.417 -1.25,22.084l25.834,-0Zm-20,-17.5c2.083,-2.084 4.583,-2.917 7.083,-2.917c2.5,0 5,0.833 7.083,2.917c3.75,3.75 3.75,10.416 0,14.166c-3.75,3.75 -10.416,3.75 -14.166,0c-3.75,-3.75 -3.75,-10 -0,-14.166Z"
                    css={css`
                      fill: #306ead;
                      fill-rule: nonzero;
                    `}
                  />
                </g>
              </g>
              <path
                d="M1091.67,874.167c0.833,-2.917 1.666,-6.25 1.666,-9.167c0,-16.667 -13.75,-30.417 -30.416,-30.417c-16.667,0 -30.417,13.75 -30.417,30.417c-0,3.333 0.417,6.25 1.667,9.167l57.5,-0Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M1194.17,874.167c0.833,-2.917 1.666,-6.25 1.666,-9.167c0,-16.667 -13.75,-30.417 -30.416,-30.417c-16.667,0 -30.417,13.75 -30.417,30.417c0,3.333 0.417,6.25 1.667,9.167l57.5,-0Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M1297.08,874.167c0.834,-2.917 1.667,-6.25 1.667,-9.167c0,-16.667 -13.75,-30.417 -30.417,-30.417c-16.666,0 -30.416,13.75 -30.416,30.417c-0,3.333 0.416,6.25 1.666,9.167l57.5,-0Z"
                css={css`
                  fill: #fff;
                  fill-rule: nonzero;
                `}
              />
            </g>
            <g>
              <path
                d="M1807.08,1511.25c0,11.25 -2.083,21.667 -5.833,31.667c-2.917,7.5 -7.083,14.583 -12.083,20.833c-1.25,2.083 -2.917,3.75 -4.584,5.417c-15.833,17.5 -38.75,28.333 -64.166,28.333l-1323.33,0c-27.916,0 -52.916,-13.333 -68.333,-33.75c-11.25,-14.583 -17.917,-32.917 -17.917,-52.5l1496.25,0Z"
                css={css`
                  fill: #dbdcdd;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M1731.67,1542.5l-943.334,0c-4.166,0 -7.083,-3.333 -7.083,-7.083c-0,-1.25 0.417,-2.917 1.25,-3.75c1.25,-2.084 3.333,-3.334 6.25,-3.334l943.333,0c4.167,0 7.084,3.334 7.084,7.084c-0,2.916 -1.667,5.416 -4.167,6.666c-1.667,0.417 -2.5,0.417 -3.333,0.417Z"
                css={css`
                  fill: #e4eaed;
                  fill-rule: nonzero;
                `}
              />
              <path
                d="M1789.17,1563.75c-1.25,2.083 -2.917,3.75 -4.584,5.417c-15.833,17.5 -38.75,28.333 -64.166,28.333l-1323.33,0c-27.916,0 -52.916,-13.333 -68.333,-33.75l1460.42,0Z"
                css={css`
                  fill: #cecece;
                  fill-rule: nonzero;
                `}
              />
            </g>
            <path
              d="M889.583,967.917l-692.083,-0c-5.417,-0 -9.583,-4.167 -9.583,-9.584l-0,-40.416c-0,-5.417 4.166,-9.584 9.583,-9.584l692.083,0c5.417,0 9.584,4.167 9.584,9.584l-0,40.416c-0,5.417 -4.167,9.584 -9.584,9.584Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_1l"
            />
            <path
              d="M1920.42,1314.58l-459.584,0c-5.416,0 -9.583,-4.166 -9.583,-9.583l0,-24.583c0,-5.417 4.167,-9.584 9.583,-9.584l459.584,0c5.416,0 9.583,4.167 9.583,9.584l0,24.583c-0,5.417 -4.167,9.583 -9.583,9.583Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_2r"
            />
            <path
              d="M1533.33,1840l-132.5,0c-5.416,0 -9.583,-4.167 -9.583,-9.583l0,-24.584c0,-5.416 4.167,-9.583 9.583,-9.583l132.5,0c5.417,0 9.584,4.167 9.584,9.583l-0,24.584c0.416,5.416 -4.167,9.583 -9.584,9.583Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_4r"
            />
            <path
              d="M767.917,1800.42l-132.5,-0c-5.417,-0 -9.584,-4.167 -9.584,-9.584l0,-24.583c0,-5.417 4.167,-9.583 9.584,-9.583l132.5,-0c5.416,-0 9.583,4.166 9.583,9.583l0,24.583c0.417,5 -4.167,9.584 -9.583,9.584Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_4l"
            />
            <path
              d="M676.25,481.667l-132.5,-0c-5.417,-0 -9.583,-4.167 -9.583,-9.584l-0,-24.583c-0,-5.417 4.166,-9.583 9.583,-9.583l132.5,-0c5.417,-0 9.583,4.166 9.583,9.583l0,24.583c0,5 -4.583,9.584 -9.583,9.584Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_4l"
            />
            <path
              d="M1322.08,570.833l-132.5,0c-5.416,0 -9.583,-4.166 -9.583,-9.583l0,-24.583c0,-5.417 4.167,-9.584 9.583,-9.584l132.5,0c5.417,0 9.584,4.167 9.584,9.584l-0,24.583c-0,5.417 -4.167,9.583 -9.584,9.583Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_4r"
            />
            <path
              d="M660.417,1335.42l-427.084,-0c-5.416,-0 -9.583,-4.167 -9.583,-9.584l0,-6.25c0,-5.416 4.167,-9.583 9.583,-9.583l427.084,0c5.416,0 9.583,4.167 9.583,9.583l0,6.25c0,5 -4.167,9.584 -9.583,9.584Z"
              css={css`
                fill: #cecece;
                fill-rule: nonzero;
              `}
              id="animate_layer_4l"
            />
            <path
              d="M1052.92,1041.67l-705.417,-0c-5.417,-0 -9.583,-4.167 -9.583,-9.584l-0,-15.833c-0,-5.417 4.166,-9.583 9.583,-9.583l705.417,-0c5.416,-0 9.583,4.166 9.583,9.583l0,15.833c0,5 -4.167,9.584 -9.583,9.584Z"
              css={css`
                fill: #306ead;
                fill-rule: nonzero;
              `}
              id="animate_layer_2l"
            />
            <path
              d="M1835,1384.17l-705.417,-0c-5.416,-0 -9.583,-4.167 -9.583,-9.584l0,-15.833c0,-5.417 4.167,-9.583 9.583,-9.583l705.417,-0c5.417,-0 9.583,4.166 9.583,9.583l0,15.833c0.417,5 -4.166,9.584 -9.583,9.584Z"
              css={css`
                fill: #306ead;
                fill-rule: nonzero;
              `}
              id="animate_layer_3r"
            />
            <path
              d="M1405.42,1755.83l-182.084,0c-5.416,0 -9.583,-4.166 -9.583,-9.583l0,-15.833c0,-5.417 4.167,-9.584 9.583,-9.584l182.084,0c5.416,0 9.583,4.167 9.583,9.584l0,15.833c0,5.417 -4.167,9.583 -9.583,9.583Z"
              css={css`
                fill: #306ead;
                fill-rule: nonzero;
              `}
              id="animate_layer_3r"
            />
            <path
              d="M567.917,566.25l-182.084,0c-5.416,0 -9.583,-4.167 -9.583,-9.583l0,-15.834c0,-5.416 4.167,-9.583 9.583,-9.583l182.084,-0c5.416,-0 9.583,4.167 9.583,9.583l0,15.834c0,5.416 -4.167,9.583 -9.583,9.583Z"
              css={css`
                fill: #306ead;
                fill-rule: nonzero;
              `}
              id="animate_layer_2l"
            />
            <path
              d="M1590,1078.75l-109.167,0c-5.416,0 -9.583,-4.167 -9.583,-9.583c0,-5.417 4.167,-9.584 9.583,-9.584l109.167,0c5.417,0 9.583,4.167 9.583,9.584c0.417,5 -4.166,9.583 -9.583,9.583Z"
              css={css`
                fill: #ef4736;
                fill-rule: nonzero;
              `}
              id="animate_layer_4r"
            />
          </g>
          <g>
            <text
              x={code ? '620.091px' : '820.091px'}
              y="1198.74px"
              css={css`
                font-family: 'Anakotmai-Medium', 'Anakotmai';
                font-weight: 500;
                font-size: 136.383px;
                fill: #ef4736;
              `}
            >
              ERROR {code}
            </text>
            <text
              x={code ? '740.091px' : '840.091px'}
              y="1268.74px"
              css={css`
                font-family: 'Anakotmai-Medium', 'Anakotmai';
                font-weight: 500;
                font-size: 136.383px;
                fill: #ef4736;
                clip-path: inset(55% 0 0 0);
              `}
            >
              ERROR {code}
            </text>
          </g>
        </g>
      </svg>
    </VStack>
  )
}
export default ErrorIcon
