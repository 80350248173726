import { Button, VStack, Link } from '@chakra-ui/react'

import ErrorIcon from '@/components/atoms/Icons/error'
import envObj from '@/lib/env'

function Error4xx(): React.ReactElement {
  return (
    <VStack
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      spacing="16px"
      p="64px"
      m="auto"
    >
      <ErrorIcon code={404} />
      <Link href={envObj.APP_URL} textDecoration="none !important">
        <Button variant="solid" colorScheme="primary">
          Return Home
        </Button>
      </Link>
    </VStack>
  )
}
export default Error4xx
